.popup-content {
    width: 95%;
    max-width: 400px;
}

.btn-custome-primary{
    background-color: #1f648c !important;
    border-color: #1f648c !important;
}

.card-custom{
    transition: border-color 1s, box-shadow 0.5s;
  }

.card-custom:hover {
    border-color: rgba(13, 110, 253, 0.7);
    box-shadow: 0px 0px 10px 2px rgba(13, 110, 253, 0.6);
  }

  